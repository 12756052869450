<template functional>
  <div
    class="lds-wrapper"
    :class="{
      abs: props.abs,
      container: props.container,
      info: props.info,
      small: props.small,
      transparent: props.transparent,
    }"
  >
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    abs: { type: Boolean, default: true },
    container: { type: Boolean, default: false },
    info: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
  },
};
</script>
