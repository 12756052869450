<template>
  <profile-layout>
    <error v-if="error" />

    <div class="lung-report" v-if="isLoaded" :class="{ completed: isCompleted }">
      <div class="profile__header">
        <div class="lung-report__header">
          <div class="lung-report__header-tools">
            <div class="lung-report__back-icon" @click="backHandler">
              <span class="icon icon__arrow icon__arrow_left" />
            </div>
            <div class="lung-report__date" v-if="date">
              <span>{{ date.date }}</span>
              <span class="time">{{ date.time }}</span>
            </div>
          </div>
          <tabs
            v-if="isCompleted"
            v-model="activeTab"
            :tabs="tabs"
            class="lung-report__tabs"
            @click="tabClickHandler"
          />
        </div>
      </div>

      <congratulate v-if="isFirst" />

      <template v-if="isElaTabActive">
        <ela
          ref="ela"
          v-if="isCompleted"
          :ela="Math.floor(lungReport.ela)"
          :ela-confidence-interval="lungReport.ela_confidence_interval"
          :ela-headline="lungReport.ela_text && lungReport.ela_text.box1"
          :ela-warning-title="warningTitle"
          :ela-warning-message="warningMessage"
          :ela-warning-cause="warningCause"
          :onLearnMoreClick="onLearnMoreClick"
        />

        <!-- <ela-chart -->
        <!--   v-if="isCompleted" -->
        <!--   :label="elaChartTitle" -->
        <!--   :ela="lungReport.ela" -->
        <!--   :healthyTrajectory="lungReport.healthy_trajectory" -->
        <!--   :noChangeTrajectory="lungReport.no_change_trajectory" -->
        <!--   :changeTrajectory="lungReport.change_trajectory" -->
        <!--   :noChangeXCoords="lungReport.no_change_x_coords" -->
        <!--   :onLearnMoreClick="onLearnMoreClick" -->
        <!-- /> -->

        <!-- <recommendations v-if="isCompleted" /> -->
      </template>

      <disclaimer-text v-if="!loading && isMetricsTabActive" />

      <processed v-if="loading" />

      <error-card v-else-if="hasError" :error-code="lungReport.error_code" :error-dict="lungReport.error_dict"/>

      <completed
        v-else-if="isCompleted && isMetricsTabActive"
        v-for="type in lungReportTypes"
        :key="type"
        :report="lungReport"
        :tab="type"
        ref="metrics"
        class="lung-report__card"
      />

      <session-input v-if="superUser" :id="id" :report="lungReport" />
    </div>
  </profile-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { dateFormatted } from "@/utils/date";
import { scrolltop } from "@/modules/utils";
// import { getStorage, setStorage } from "@/modules/localStorage";
import { getStorage, setStorage } from "@/modules/localStorage";

import ProfileLayout from "@/components/profile/profileLayout";
import Tabs from "@/components/profile/profileTabs";
import Error from "@/components/error";
import Completed from "./components/completed";
import Ela from "./components/ela";
import { saveLog } from '@/modules/logger';
// import ElaChart from "./components/elaChart";
// import Recommendations from "./components/recommendations";
import Processed from "./components/processed";
import DisclaimerText from "./components/disclaimerText";
import ErrorCard from "./components/error";
import Congratulate from "./components/congratulate";
import SessionInput from "./components/sessionInput";
import logVisiting from "@/mixins/logVisiting";

import {
  ROUTE_PROFILE_HISTORY,
  STORE_GET_LUNG_REPORT,
  STORE_CLEAR_LUNG_REPORT,
  STORE_GET_AI_DATA,
  LUNG_REPORT_TYPES,
  LUNG_REPORT_TABS,
  STORE_GET_CHARACTERISTICS
} from "@/constants";

export default {
  name: "sessionDetailedPage",

  components: {
    ProfileLayout,
    Tabs,
    Completed,
    Processed,
    DisclaimerText,
    ErrorCard,
    Congratulate,
    Error,
    SessionInput,
    Ela,
    // ElaChart,
    // Recommendations
  },

  mixins: [logVisiting],

  data: () => ({
    activeTab: Object.keys(LUNG_REPORT_TABS)[0],
    tabs: LUNG_REPORT_TABS,
    lungReportTabs: Object.keys(LUNG_REPORT_TABS),
    lungReportTypes: Object.keys(LUNG_REPORT_TYPES),
    error: false,
    congratulated: getStorage("congratulated"),
    elaChartTitle: "Projected Lung Function",
    inferenceInProgress: false,
    reportPollID: null,
  }),
  computed: {
    ...mapState({
      lungReport: "lungReport",
      aiLoading: "aiLoading",
      superUser: ({user}) => user.is_superuser,
      isSuper: ({ user }) => user.superUserSignedIn,
      weight: ({ characteristics }) => characteristics.weight
    }),

    loading() {
      return this.aiLoading[this.id] || this.lungReport?.error_code == -1;
    },

    isLoaded() {
      return this.lungReport !== null;
    },

    isPartial() {
      return this.lungReport?.end_date === null;
    },

    isCompleted() {
      return this.lungReport?.error_code === 0;
    },

    isFirst() {
      return this.isCompleted && !this.congratulated;
    },

    inferenceInFlight() {
      return [-1].includes(this.lungReport?.error_code)
    },

    isNotLoaded() {
      return [null, 1].includes(this.lungReport?.error_code);
    },

    isElaTabActive() {
      return this.activeTab === this.lungReportTabs[1];
    },

    isMetricsTabActive() {
      console.log("Is metrics tab active");
      console.log(this.activeTab === this.lungReportTabs[0]);
      return this.activeTab === this.lungReportTabs[0];
    },

    hasError() {
      const err = this.lungReport?.error_code;
      return ![null, -1,  0].includes(err);
    },

    id() {
      return +this.$route.params?.id ?? 0;
    },

    sess_end() {
      return this.$route.params?.sess_end ?? false;
    },

    date() {
      const {
        isLoaded,
        // lungReport: { end_date }
        lungReport
      } = this;

      
      if (!isLoaded || !lungReport ||  (lungReport != null && !lungReport.end_date)) return null;

      const end_date = lungReport.end_date;
      return {
        date: dateFormatted(end_date),
        time: dateFormatted(end_date, "time")
      };
    },

    warningTitle() {
      return this.lungReport?.ela_text?.box2?.title;
    },

    warningMessage() {
      return this.lungReport?.ela_text?.box2?.message;
    },

    warningCause() {
      return this.lungReport?.ela_text?.box2?.cause;
    }
  },

  inject: {
    showModal: { default: function() {} }
  },

  async mounted() {
    await this.getReport();
    console.log(this.lungReport)
  },

  beforeDestroy() {
    if(this.reportPollID != null) {
      console.log("No more timeouts")
      clearTimeout(this.reportPollID);  // prevent page print 
    }
    this[STORE_CLEAR_LUNG_REPORT]();
  },

  methods: {
    ...mapActions([
      STORE_GET_LUNG_REPORT,
      STORE_CLEAR_LUNG_REPORT,
      STORE_GET_AI_DATA,
      STORE_GET_CHARACTERISTICS
    ]),

    ...mapState({
      aiLoading: "aiLoading",
    }),

    tabClickHandler() {
      scrolltop(0);
    },

    onLearnMoreClick() {
      this.tabClickHandler();
      this.activeTab = this.lungReportTabs[0];
    },

    getReport() {
      return this[STORE_GET_LUNG_REPORT](this.id)
        .then(() => {
          console.log("Got report")
          console.log(this.lungReport)
          if (this.sess_end) {
            saveLog("session-end-lpr-generated", {
                      user_id: getStorage("user")["user_id"],
                      "notification_info": {
                      "lpr_error_code": this.lungReport?.error_code,
                      "email": getStorage("user")["email"]
            }});
          }
              
          if ([-1].includes(this.lungReport?.error_code)) {
            // schedule call to getReport in 5s and return
            this.reportPollID = setTimeout(() => {
              this.getReport()
            }, 5000)
            return
          }
          if (this.isPartial) {
            this.backHandler();
          } else if (this.isNotLoaded) {
            if (!getStorage("inferenceInFlight")) {
            // if (!this.inferenceInProgress) {
              console.log(getStorage("inferenceInFlight"))
              console.log("not loading")
              console.log(this.aiLoading[this.id])
              console.log(this.aiLoading)
              this.inferenceInProgress = true
              this.getAIdata();
              this.reportPollID = setTimeout(() => {
                this.getReport()
              }, 5000)
              return 
            }
            else if (this.inferenceInFlight) {
              console.log("Waiting for LPR to be generated")
            }
            else {
              console.log("Skipping inference")
              console.log(getStorage("inferenceInFlight"))
              saveLog("already-loading-lpr", 
                {msg: "getReport does not have report available, but state says inference call is in-flight"})
            }
          }
          else {
            setStorage({"inferenceInFlight": false})
          }
        })
        .catch(this.reportErrorHandler);
    },

    getAIdata() {
      return this[STORE_GET_AI_DATA](this.id);
    },

    backHandler() {
      this.$router.push({ name: ROUTE_PROFILE_HISTORY }, null, () => {});
    },

    reportErrorHandler(e) {
      console.log("reporterrorhandler")
      print(e)
      if (e?.response?.status === 404) return this.backHandler();
      return this.commonErrorHandler();
    },

    commonErrorHandler() {
      this.error = true;
    }
  }
};
</script>
