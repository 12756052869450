<template>
  <div class="flat-bar">
    <div class="flat-bar__inner">
      <div class="flat-bar__lln" :style="styles.lln">
        <div class="flat-bar__wrapper">
          <div>LLN</div>
          <div>{{ lln }}</div>
        </div>
      </div>
      <div class="flat-bar__normal" :style="styles.expected">
        <div class="flat-bar__wrapper">
          <div>Expected</div>
          <div>{{ expected }}</div>
        </div>
      </div>
      <div class="flat-bar__value" :style="styles.value">
        <div class="flat-bar__wrapper">
          <div>You</div>
          <div>{{ value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lln: { type: Number, required: true },
    expected: { type: Number, required: true },
    value: { type: Number, required: true },
    unit: { type: String, default: "" },
  },

  computed: {
    min() {
      return Math.min(this.lln, this.expected, this.value);
    },

    max() {
      return Math.max(this.lln, this.expected, this.value);
    },

    diff() {
      return this.max - this.min;
    },

    part() {
      return this.diff / 3;
    },

    range() {
      return this.max + this.part - (this.min - this.part);
    },

    start() {
      return this.min - this.part;
    },

    localValues() {
      return {
        lln: this.lln - this.start,
        expected: this.expected - this.start,
        value: this.value - this.start,
      };
    },

    styles() {
      return {
        lln: {
          width: `${((this.localValues.lln * 100) / this.range).toFixed(1)}%`,
        },
        expected: {
          width: `${((this.localValues.expected * 100) / this.range).toFixed(
            1
          )}%`,
        },
        value: {
          width: `${((this.localValues.value * 100) / this.range).toFixed(1)}%`,
        },
      };
    },
  },
};
</script>
