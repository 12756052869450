<template>
  <card>
    <template v-slot:title>Invalid session</template>

    <div v-if="errorCode > 1" style="white-space: pre-line;">
      Our voice analysis engine detected the following problems with the audio recordings.
      <br>

      <!-- {{ errorMsg }} -->
      <div v-html=errorMsg></div>
      Please <a href="#" @click.prevent="recordHandler"> record a new sample </a>
      and be sure to follow the recording instructions closely. If you have a
      specific question, please contact us
      <a href="#" @click.prevent="showContactPopup"> here. </a>
      <br />
      <!-- (Error code: {{ errorCode }}) -->

    </div>

    <div v-else-if="errorCode >= 4 && errorCode <= 7">
      Our voice analysis engine detected a problem with the
      <b>{{ wrongPath }}</b>
      recording. Please
      <a href="#" @click.prevent="recordHandler"> record a new sample </a>
      and be sure to follow the recording instructions closely. If you have a
      specific question, please contact us
      <a href="#" @click.prevent="showContactPopup"> here. </a>
      <br />
      <!-- (Error code: {{ errorCode }}) -->
    </div>

    <div v-else-if="errorCode === 9">
      Our voice analysis engine detected excessive noise during the “Sustained Ahh” recording.
      Please
      <a
        href="#"
        @click.prevent="recordHandler"> record a new sample </a> 
        in a quieter location and be sure to speak in your normal volume.
        If you have a specific question, please contact us
      <a href="#" @click.prevent="showContactPopup"> here.</a>
      <br />
      <!-- (Error code: {{ errorCode }}) -->
    </div>

    <div v-else>
      Our voice analysis engine is experiencing unusually high traffic. Try
      reloading this page and, if that doesn’t fix the problem, please try again
      later. If you have a specific question, please contact us
      <a href="#" @click.prevent="showContactPopup"> here. </a>
      <br />
      <!-- (Error code: {{ errorCode }}) -->
    </div>
  </card>
</template>

<script>
import Card from "@/components/card";
import { POPUP_CONTACT_US, ROUTE_PROFILE_RECORD } from "@/constants";
import {RECORD_FILE_DATA } from "@/constants"
import { mapState } from "vuex";

export default {
  components: { Card },

  inject: {
    showModal: { default: () => {} }
  },

  props: {
    errorCode: { type: Number, required: true },
    errorDict : { type: JSON, required: true }
  },

  data: () => ({
    codeDesc: {
      4: 'Sustained "Aah"',
      5: "Forceful Exhale",
      6: "Reading Passage",
      7: "Word List"
    }
  }),

  computed: {
    ...mapState({
      isCareTaker: ({ user }) => user.caretaker,
    }),
    wrongPath() {
      return this.codeDesc[this.errorCode];
    },

    errorMsg() {
      var message = "<br>";
      for (let [key, value] of Object.entries(this.errorDict)) {
        console.log(key, value)
        var audiokey = "audio"+key;
        console.log(audiokey)
        console.log(RECORD_FILE_DATA[audiokey])

        message += "<b>"+RECORD_FILE_DATA[audiokey].title + ":</b><br>&emsp;" + value + "<br>"

      }
      message += "<br>"
      return message
    }
  },

  mounted() {
    console.log("In mounted for error card")
    console.log(this.errorCode)
    console.log(this.errorDict)

    
  },

  methods: {
    showContactPopup() {
      this.showModal(POPUP_CONTACT_US);
    },

    recordHandler() {
      if(!this.isCareTaker)
        this.$router.push({ name: ROUTE_PROFILE_RECORD }, null, () => {});
    }
  }
};
</script>
