import { render, staticRenderFns } from "./congratulate.vue?vue&type=template&id=1c9e1f0f&scoped=true&functional=true&"
var script = {}
import style0 from "./congratulate.vue?vue&type=style&index=0&id=1c9e1f0f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "1c9e1f0f",
  null
  
)

export default component.exports