<template>
  <div class="card">
    <div class="ela__heading">
      <div class="ela__lungs-bg" />
      <h3 class="ela__your-ela-heading">Your ELA:</h3>
      <p class="ela__value">
        {{ela || 0}}<span v-if="elaCI !== 0">±{{elaCI}}</span>
        <span class="ela__value-description">years</span>
      </p>
    </div>
    <div class="ela__content">
      <div class="ela__content-title-wrapper">
        <h5 class="ela__content-title">Estimated Lung Age (ELA)</h5>
        <tooltip class="ela__content-tip" v-if="tip">{{ tip }}</tooltip>
      </div>
      <p class="ela__content-text" v-if="elaHeadline" v-html="elaHeadline"></p>
      <p class="ela__content-text" v-else>No headline</p>
      <div class="ela__warning" v-if="elaWarningTitle">
        <h5 class="ela__warning-title">
          <span class="ela__warning-icon"></span>
          {{ elaWarningTitle }}<span v-if="elaWarningCause">: {{elaWarningCause}}</span>
        </h5>
        <p class="ela__warning-text" v-html="elaWarningMessage"></p>

        <div class="ela__left-border"></div>
      </div>
      <p class="ela-chart__notion-text">
        Your ELA is based on many underlying lung function metrics.
        <a
          href
          @click="onLearnMoreClickHandler($event)"
        >Learn More</a>
      </p>
    </div>
  </div>
</template>

<script>
import { ROUTE_PROFILE_DASHBOARD } from "@/constants";
import Tooltip from "@/components/tooltip";

export default {
  components: { Tooltip },

  props: {
    ela: { type: Number },
    elaConfidenceInterval: { type: Number },
    elaHeadline: { type: String },
    elaWarningTitle: { type: String },
    onLearnMoreClick: { type: Function, default: () => () => {} },
    elaWarningMessage: { type: String },
    elaWarningCause: { type: String }
  },

  data: () => ({
    dashboardRoute: { name: ROUTE_PROFILE_DASHBOARD }
  }),

  computed: {
    list() {
      if (!this.report[this.tab]) return [];
      return this.report[this.tab].filter(item => item.value !== null);
    },

    volumeDynamicTextCode() {
      return this.report?.volume_text || "";
    },

    tip() {
      if (process.env.NODE_ENV === "test") {
        return null;
      }

      return (
        "Estimated Lung Age (ELA) is the average age of a healthy weight, non-smoker in your demographic group" +
        "(sex, height, and ethnicity) with your lung function metrics."
      );
    },

    elaCI() {
      return Math.floor(this.elaConfidenceInterval) || 0;
    }
  },
  methods : {
    onLearnMoreClickHandler(event) {
      event.preventDefault();
      this.onLearnMoreClick("metrics");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";

.ela-chart__notion-text {
  font-size: 16px;
  line-height: 26px;
  color: $color-dark-light;
  margin-top: 0;

  & > a {
    font-size: 16px;
    line-height: 26px;
    color: $color-blue;
    text-transform: none;
  }
}
.ela__heading {
  padding: 11px 26px;
  background-color: $color-primary-light;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  color: $color-white;
  position: relative;
}

.ela__lungs-bg {
  background-image: url("~@/assets/images/lungs-blue.svg");
  background-repeat: no-repeat;
  background-size: 100px 100px;
  background-position: calc(100% - 20px) -8px;
  opacity: 0.4;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.ela__your-ela-heading {
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
  color: inherit;
  margin: 0;
}

.ela__value {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  margin: 0;
}

.ela__value-sign {
  font-weight: normal;
}

.ela__value-description {
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  text-transform: uppercase;
  padding-left: 10px;
}

.ela__content {
  padding: 16px 26px;
}

.ela__content-title {
  font-weight: $weight-bold;
  font-size: $size-sm;
  color: $color-primary;
}

.ela__content-text {
  font-size: 16px;
  line-height: 26px;
  color: $color-dark-light;
}

.ela__warning {
  padding: 12px 16px;
  border: 1px solid #ef4e4e;
  border-radius: 3px;
  position: relative;
}

.ela__warning-title {
  font-weight: 900;
  font-size: 14px;
  line-height: 26px;
  color: #ef4e4e;
}

.ela__warning-text {
  font-size: 16px;
  line-height: 26px;
  color: darken(#ef4e4e, 40%);

  & a {
    text-decoration: none;
    color: $color-secondary-dark;
    font-weight: $weight-bold;
  }
}

.ela__left-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #ef4e4e;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.ela__warning-icon {
  display: inline-block;
  width: 16px;
  height: 14px;
  background-image: url("~@/assets/images/warning.svg");
  background-repeat: no-repeat;
  background-size: 16px 14px;
  margin-right: 4px;
}

.ela__content-title-wrapper {
  display: flex;
  align-items: center;
}

.ela__content-tip {
  margin: 0 0.5rem 0.5rem;
}
</style>
