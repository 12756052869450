<template>
  <div class="lung-report-card">
    <card class="lung-report-card__card">
      <h3 class="lung-report-card__label">{{ title }}</h3>
      <volume-text v-if="isVolume" :code="volumeDynamicTextCode" />
      <div class="lung-report-card__description" v-if="isBreathlessness">
        Changes in frequency and duration of pauses during speech can be an
        indicator of changes in lung function. We will track these statistics
        over time on the
        <router-link :to="dashboardRoute">Dashboard</router-link> to gauge
        changes in your lung function.
      </div>
      <div class="lung-report-card__description" v-if="isFeatures">
        Changes in voice condition and cough frequency can be indicators of
        changes in lung function. We will track these statistics over time on
        the
        <router-link :to="dashboardRoute">Dashboard</router-link> to gauge
        changes in your lung function.
      </div>

      <carousel
        :per-page="1"
        :loop="true"
        pagination-active-color="#4aa3f3"
        :space-padding="20"
        :navigationEnabled="true"
        class="lung-report-card__carousel"
        navigationNextLabel=" "
        navigationPrevLabel=" "
      >
        <slide v-for="(item, index) in filtered_metrics" :key="index">
          <v-report :item="item">
            <v-labels v-if="isVolume" />
          </v-report>
        </slide>
      </carousel>
    </card>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

import Card from "@/components/card";
import vReport from "./report";
import vLabels from "./labels";
import VolumeText from "./volumeDynamicText";
import { LUNG_REPORT_TYPES, ROUTE_PROFILE_DASHBOARD } from "@/constants";

export default {
  components: { Card, vReport, vLabels, VolumeText, Carousel, Slide },

  props: {
    tab: { type: String, required: true },
    report: { type: Object, required: true }
  },

  data: () => ({
    dashboardRoute: { name: ROUTE_PROFILE_DASHBOARD }
  }),

  computed: {
    list() {
      if (!this.report[this.tab]) return [];
      console.log("completed-list");
      console.log(this.tab);
      console.log(this.report);
      return this.report[this.tab].filter(item => item.value !== null);
    },
/*eslint-disable */
    filtered_metrics() {
      console.log("filtered_metrics");
      return this.list.filter(item => {
                      if (item.value == -1 && item.title == "FEV1") return false;
                      return true;
                    })
    },
/*eslint-enable */

    volumeDynamicTextCode() {
      return this.report?.volume_text || "";
    },

    isVolume() {
      return this.tab === Object.keys(LUNG_REPORT_TYPES)[0];
    },

    isBreathlessness() {
      return this.tab === Object.keys(LUNG_REPORT_TYPES)[1];
    },

    isFeatures() {
      return this.tab === Object.keys(LUNG_REPORT_TYPES)[2];
    },

    title() {
      return LUNG_REPORT_TYPES[this.tab] || this.tab;
    }
  }

};
</script>
