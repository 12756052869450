<template>
  <div class="card-box">
    <div class="lung-report-card__item card-box__body">
      <div class="lung-report-card__header">
        <h4 class="lung-report-card__title">{{ item.title }}</h4>
        <div class="lung-report-card__value">
          <span>{{ `${item.value} ${item.unit}` }}</span>
          <span v-if="item.relativity" class="lung-report-card__relativity">
            {{ item.relativity }}% of expected
          </span>
        </div>
      </div>
      <div class="lung-report-card__text">{{ item.description }}</div>
      <flat-bar
        v-if="isAllData"
        class="lung-report-card__bar"
        :lln="item.lln"
        :expected="item.expected"
        :value="item.value"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import FlatBar from "@/components/charts/flatBar";

export default {
  components: { FlatBar },

  props: {
    item: { type: Object, required: true },
  },

  computed: {
    isAllData() {
      const { item } = this;

      return item.lln && item.expected && item.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-box {
  height: 100%;
  padding: 0 5px;

  &__body {
    height: 100%;
    border-radius: 5px;
    background-color: #eee;
    padding: 15px;
  }
}
</style>
