<template>
  <div class="lung-report-card__description" v-if="code">
    <template v-if="code === 1">
      Both your FVC and PDR are greater than expected. Good job!
    </template>
    <template v-if="code === 2">
      Both your FVC and PDR values are in the normal range. Record samples
      regularly to track potential changes in lung volume.
    </template>
    <template v-if="code === 3">
      While your FVC is below normal, your PDR is above average for your
      demographic group. We may have made a mistake, please submit another
      sample and if you get the same result contact us
      <a href="#" @click.prevent="contactUsHandler" class="link-contact-us">
        here.
      </a>
    </template>
    <template v-if="code === 4 || code === 5">
      Both your FVC and PDR values are in the normal range.
    </template>
    <template v-if="code === 6">
      While your FVC is below normal, your PDR is in the normal range. We may
      have made a mistake, please submit another sample and if you get the same
      result contact us
      <a href="#" @click.prevent="contactUsHandler" class="link-contact-us">
        here.
      </a>
    </template>
    <template v-if="code === 7">
      While your PDR is below normal, your FVC is above expected. This result
      may indicate that you did not exert maximum effort during the Sustained
      “Ahh” recording.
    </template>
    <template v-if="code === 8">
      While your PDR is below normal, your FVC is in the normal range. This
      result may indicate that you did not exert maximum effort during the
      Sustained “Ahh” recording.
    </template>
    <template v-if="code === 81">
      While your PDR is below normal, your FVC is in the normal range. This
      result may indicate that you did not exert maximum effort during the
      Sustained “Ahh” recording. If you consistently get this result even when
      exerting maximum effort, you may want to consult a medical professional.
      <router-link v-if="!isCareTaker" :to="recordRoute">Record new sample now.</router-link>
    </template>
    <template v-if="code === 9">
      Both your FVC and PDR values are below the normal range. Given your lack
      of symptoms (cough, fever, breathlessness, etc.), this likely indicates
      that you did not exert maximum effort during the recording process.
    </template>
    <template v-if="code === 91">
      Both your FVC and PDR values are below the normal range. If you
      consistently get this result when exerting maximum effort during the
      recording process, you may want to consult a medical professional.
      <router-link v-if="!isCareTaker" :to="recordRoute">Record new sample now.</router-link>
    </template>
  </div>
</template>

<script>
import { ROUTE_PROFILE_RECORD, POPUP_CONTACT_US } from "@/constants";
import { mapState } from "vuex";

export default {
  props: {
    code: { type: Number, default: 0 },
  },

  inject: {
    showModal: { default: () => {} },
  },

  computed: {
    ...mapState({
      isCareTaker: ({ user }) => user.caretaker,
    }),
  },

  data: () => ({
    recordRoute: { name: ROUTE_PROFILE_RECORD },
  }),

  methods: {
    contactUsHandler() {
      this.showModal(POPUP_CONTACT_US);
    },
  },
};
</script>
