<template>
  <card>
    <div class="processed">
      <div class="processed__icon-wrapper">
        <img src="@/assets/images/lungs-animation.gif" alt="lung" />
      </div>
      <div class="processed__title">
        Generating your Lung Performance Report™
      </div>
      <div class="processed__loading">
        <div class="processed__loader">
          <loader info container small />
        </div>
        <div>Please Wait</div>
      </div>
    </div>
  </card>
</template>

<script>
import Card from "@/components/card";
import Loader from "@/components/loader";

export default {
  components: { Card, Loader },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";
@import "@/assets/styles/basic/mixins";

.processed {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;

  @include media-mobile {
    padding: 1rem 0;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
    border-radius: 50%;

    img {
      height: 100%;
      width: 100%;
      margin-top: -0.3rem;
    }
  }

  &__title {
    color: $color-primary;
    font-weight: $weight-bold;
    font-size: $size-md;
    text-align: center;
  }

  &__loading {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    color: $color-info-dark;
    font-weight: $weight-bold;
    font-size: $size-sm * 1.1;
  }

  &__loader {
    position: relative;
    width: 35px;
    height: 35px;
    margin-right: 0.75rem;
  }
}
</style>
