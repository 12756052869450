<template>
  <div class="lung-report-card">
    <h3 class="lung-report-card__label">Session Input</h3>
    <card class="lung-report-card__card">
      <div v-if="error" v-html="error" />
      <template v-else>
        <!-- <div class="lung-report-card__item"> -->
        <!--   <div -->
        <!--     class="lung-report-card__header" -->
        <!--     v-for="(item, index) in symptomScores" -->
        <!--     :key="index" -->
        <!--   > -->
        <!--     <h4 class="lung-report-card__title">{{ item.title }}</h4> -->
        <!--     <div class="lung-report-card__value">{{ item.value }}</div> -->
        <!--   </div> -->
        <!--   <div class="lung-report-card__header"> -->
        <!--     <h4 class="lung-report-card__title">TOTAL SCORE</h4> -->
        <!--     <div class="lung-report-card__value">{{ totalScore }}</div> -->
        <!--   </div> -->
        <!-- </div> -->

        <!-- <div class="lung-report-card__item"> -->
        <!--   <div -->
        <!--     class="lung-report-card__header" -->
        <!--     v-for="(item, index) in audioLinks" -->
        <!--     :key="index" -->
        <!--   > -->
        <!--     <h4 class="lung-report-card__title">{{ item.title }}</h4> -->
        <!--     <div class="lung-report-card__value"> -->
        <!--       <a -->
        <!--         :href="item.link" -->
        <!--         class="link" -->
        <!--         target="_blank" -->
        <!--         rel="noopener noreferrer" -->
        <!--         >Download</a -->
        <!--       > -->
        <!--     </div> -->
        <!--   </div> -->
        <!-- </div> -->

        <div class="lung-report-card__item">
          <pre>{{ formattedReport }}</pre>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import { ajaxGetSessionInput } from "@/api";
import { formatErrorsToString, capitalize } from "@/modules/utils";
import Card from "@/components/card";
import {
  FILLING_INITIAL_DATA,
  RECORD_FILE_DATA,
  AWS_CREDENTIALS,
} from "@/constants";

export default {
  components: { Card },

  props: {
    id: { type: Number, required: true },
    report: { type: Object, default: () => ({}) },
  },

  data: () => ({
    sessionData: {},
    error: null,
    list: FILLING_INITIAL_DATA,
  }),

  computed: {
    symptomScores() {
      return this.list.map((el) => ({
        title: capitalize(el.type),
        value: this.sessionData[el.type] || 0,
      }));
    },

    totalScore() {
      return this.symptomScores.reduce((acc, { value }) => (acc += value), 0);
    },

    audioLinks() {
      return Object.entries(RECORD_FILE_DATA).map(([key, { title }]) => ({
        title,
        link: this.audioLink(this.sessionData[key]),
      }));
    },

    formattedReport() {
      return JSON.stringify(this.report, null, 2);
    },
  },

  mounted() {
    this.getSessionData();
  },

  methods: {
    getSessionData() {
      ajaxGetSessionInput(this.id)
        .then(this.onSuccess)
        .catch(this.onError);
    },

    audioLink(fileName) {
      const bucket = AWS_CREDENTIALS.AWS_ALBUM_BUCKET_NAME;
      const region = AWS_CREDENTIALS.AWS_BUCKET_REGION;
      return `https://s3.${region}.amazonaws.com/${bucket}/${fileName}`;
    },

    onSuccess({ data }) {
      this.sessionData = data.session;
    },

    onError(e) {
      if (e?.response?.data) {
        this.error = formatErrorsToString(e.response.data);
      }
    },
  },
};
</script>
